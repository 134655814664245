import React, { useState } from "react"
import Layout from "../components/layout"
import {Jumbotron, Form, Button, Alert} from 'react-bootstrap'
import '../styles/contact.css';

export const seo = {
  title: 'Own A Franchise | Crampete',
  description: 'Want to build a business in the fast-growth education industry? You can become a successful entrepreneur in your own city. Contact 1800-5722-422 or franchise@crampete.com.',
  keywords: 'Crampete, Own A Franchisee',
  image: '',
  url: 'https://www.crampete.com/franchise',
  author: 'Crampete'
}

const Franchise = () => {

  const [ formData, setFormData ] = useState(
    {
      fields: {},
      errors: {},
      success: '',
      submitState : false
    }
  )

  const handleValidation = () =>{
    let fields = formData.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
      formIsValid = false;
      errors["name"] = "Please enter your name";
    }

    //Name
    if(!fields["city"]){
      formIsValid = false;
      errors["city"] = "Please enter your city";
    }

    //Name
    if(!fields["website"]){
      formIsValid = false;
      errors["website"] = "Please enter your website url";
    }

    //Mobile
    if(!fields["mobile"]){
    formIsValid = false;
    errors["mobile"] = "Please enter your mobile number";
    }
    else if(fields["mobile"].length !== 10)
    {
      formIsValid = false;
      errors["mobile"] = "Please enter valid mobile number";
    }

    //Email
    if(!fields["email"]){
    formIsValid = false;
    errors["email"] = "Please enter your email";
    }

    if(typeof fields["email"] !== "undefined"){
    let lastAtPos = fields["email"].lastIndexOf('@');
    let lastDotPos = fields["email"].lastIndexOf('.');

    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
      formIsValid = false;
      errors["email"] = "Please enter valid email";
    }
    }
    
    

   setFormData((prevState) => {
    return { ...prevState, errors: errors };
   });
   return formIsValid;
   } 

   const handleChange = (field, e) => {  
    let fields = formData.fields;
    fields[field] = e.target.value;        
    setFormData((prevState) => {
      return { ...prevState, fields: fields };
     });
   }

   const handleSubmit = async event => {
    event.preventDefault()
    handleValidation()
    if(handleValidation()){

      setFormData((prevState) => {
        return { ...prevState, submitState: true };
       });

      document.getElementById("franchise-submit").innerHTML = 'Please wait...';
      

      const data = {
        name: formData.fields["name"],
        email: formData.fields["email"],
        mobile: formData.fields["mobile"],
        website: formData.fields["website"],
        city: formData.fields["city"],
        message: formData.fields["message"]
      }

      const admin_opts = {
        "ToAddress": "info@crampete.com",
        "ReplyToAddress": "no-reply@crampete.com",
        "EmailTemplate": "FranchiseeAdminTemplate",
        "Payload": JSON.stringify(data)
      }
      
      //Admin Email
      fetch('https://gsq4d48100.execute-api.ap-south-1.amazonaws.com/dev/send-email', {
          method: 'post',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(admin_opts)
          }).then(function(response) {
            return response.json();
          }).then(function(data) {
            // console.log(data);
            if(data.success) {

              // setFormData({success: true});
              setFormData((prevState) => {
                return { ...prevState, success: true };
               });

              document.getElementById("franchise-submit").innerHTML = 'Submit';
              
                
            } else {
              setFormData((prevState) => {
                return { ...prevState, success: false };
               });
              document.getElementById("franchise-submit").innerHTML = 'Submit';
            }

            

            setTimeout(
              () => setFormData((prevState) => {
                return { ...prevState, submitState: false, fields: {},success: false  };
               }),
              5000
            )
            document.getElementById("franchise-form").reset()
            
          });


          //Student Email
          const std_opts = {
            "ToAddress": formData.fields["email"],
            "ReplyToAddress": "no-reply@crampete.com",
            "EmailTemplate": "FranchiseeTemplate",
            "Payload": JSON.stringify(data)
          }

          fetch('https://gsq4d48100.execute-api.ap-south-1.amazonaws.com/dev/send-email', {
          method: 'post',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(std_opts)
          }).then(function(response) {
            return response.json();
          }).then(function(data) {
             
          });

    } 
   }
  
  // console.log(formData)

  const rspSuccess = (
    <Alert 
      className={`${formData.success === true ? "d-block" : "d-none"}`}
      variant="success"
      style={{ fontSize: "14px", textAlign: "center" }}
    >
      Thank you, your enquiry has been submitted !
    </Alert>
  )
  const rspFail = (
    <Alert
      className={`${formData.success === false ? "d-block" : "d-none"}`}
      variant="danger"
      style={{ fontSize: "14px", textAlign: "center" }}
    >
      Something went wrong please try again later!
    </Alert>
  )
  const submitMsg = formData.success ? rspSuccess : rspFail

  return (

  <Layout title={seo.title} description={seo.description} image={seo.image} keywords={seo.keywords} url={seo.url} author={seo.author} >
    <Jumbotron className="bg-lightblue">
      <div class="container">
        <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
          <h2>Nurture the Entrepreneur in You</h2>
          <p className="mt-4">
          <b>Become a Crampete Learning Centre Partner</b>, with our expert guidance and battle-hardened operations, marketing and training systems, you could soon become a successful Entrepreneur.
          </p>
        </div>
      </div>
    </Jumbotron>  
    <div class="contactPageSection pt-2 pb-7">
    <div class="container">
        <div className="contactFormOuter">
           <h3>I'm Interested in this franchisee!</h3>
           <Form id="franchise-form" method="post" onSubmit= {handleSubmit}>
           {formData.submitState ? submitMsg : ""}
            <div className="row">
              <div className="col-md-4">
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" onChange={handleChange.bind(this, "name")}/>
                  <Form.Text className="text-danger">{formData.errors["name"]}</Form.Text>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="email" onChange={handleChange.bind(this, "email")}/>
                  <Form.Text className="text-danger">{formData.errors["email"]}</Form.Text>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group controlId="mobile">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="number" name="mobile" onChange={handleChange.bind(this, "mobile")}/>
                  <Form.Text className="text-danger">{formData.errors["mobile"]}</Form.Text>
                </Form.Group>
              </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" name="city" onChange={handleChange.bind(this, "city")}/>
                  <Form.Text className="text-danger">{formData.errors["city"]}</Form.Text>
                </Form.Group>
                </div>
                <div className="col-md-6">
                <Form.Group controlId="website">
                  <Form.Label>Website</Form.Label>
                  <Form.Control type="text" name="website" onChange={handleChange.bind(this, "website")}/>
                  <Form.Text className="text-danger">{formData.errors["website"]}</Form.Text>
                </Form.Group> 
                </div>
            </div>
            <div className="row">
              <div className="col-md-12">
              <Form.Group controlId="message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows="5" name="message" onChange={handleChange.bind(this, "message")}/>
                  <Form.Text className="text-danger"></Form.Text>
                </Form.Group>
              </div>
            </div>
            <Button variant="primary" type="submit" id="franchise-submit">
             Yes, I'm interested
            </Button>
           </Form>
        </div>
    </div>
    </div>
  </Layout>
)
  }

export default Franchise  
